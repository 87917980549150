<template>
  <el-date-picker
    v-model="value"
    type="daterange"
    align="right"
    size="large"
    unlink-panels
    :range-separator="'bis'"
    :picker-options="pickerOptions"
    :start-placeholder="format(startOfYear(new Date()), 'yyyy-MM-dd')"
    :end-placeholder="format(endOfYear(new Date()), 'yyyy-MM-dd')"
    @change="onChange"
  ></el-date-picker>
</template>

<script>
import { endOfYear, format, startOfYear } from 'date-fns';

export default {
  name: 'DatePicker',

  props: {
    pickerOptions: {
      type: Object,
      required: false,
      default() {
        return this.defaultPickerOptions;
      }
    }
  },

  data() {
    return {
      defaultPickerOptions: {
        defaultShortcuts: [
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      value: []
    };
  },

  methods: {
    endOfYear,
    format,
    startOfYear,

    onChange(item) {
      this.$emit('changeDate', item);
    }
  }
};
</script>

<style lang="scss">
.el-range-editor {
  background-color: $c-page-bg;
  min-width: 400px;
}

.el-range-input {
  background-color: transparent;
}
</style>
